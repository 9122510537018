body {
  background: #200122;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6f0000, #200122);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6f0000, #200122); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.searchForm {
    width: 100%;
    height: 10vh;
    background-color: pink;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.searchBar {
    width: 80%;
    height: 50%;
    text-align: center;
    font-size: 20px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border-color: white;
}
.images {
    width: 100%;
    height: auto;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div {/*changedivtoreferenceyourelementsyouputin<Masonry>*/
    margin-bottom: 10px;
}
.notification {
  display: block;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 25px;
}